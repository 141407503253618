<template>
    <div class="bg-gray-100 rounded overflow dm-sans-font">
        <div class="flex justify-between align-items-center p-2">
            <button @click="clearDataField('reset')" class="bg-white text-xs rounded border shadow-sm p-1">{{ $t('clear') }}</button>
            <small class="text-xs">{{ $t('filter') }}</small>
            <button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">{{ $t('done') }}</button>
        </div>
        <div class="flex flex-col">
            <div>
                <!-- <div class="bg-white border-t border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="date"/>
                        <span class="ml-2 cursor-pointer">{{ $t('date') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'date'" class="p-2">
                    <div class="form-group">
                        <select v-model="dateObject.range" class="form-select w-full">
                            <option selected disabled>---</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                        </select>
                    </div>
                    <div class="block">
                        <el-date-picker
                        size="mini"
                        v-model="dateObject.date"
                        type="daterange"
                        format="yyyy-MM-dd"
                        range-separator="|"
                        start-placeholder="Start date"
                        end-placeholder="End date">
                        </el-date-picker>
                    </div>
                </div> -->
                <div class="bg-white border-b">
                    <label class="inline-flex align-items-center item-center mt-1 ml-3">
                        <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="price"/>
                        <span class="ml-2 cursor-pointer">{{ $t('price') }}</span>
                    </label>
                </div>
                <div v-if="filter === 'price'" class="p-2">
                    <div class="form-group">
                        <select v-model="priceObject.range" class="form-select w-full">
                            <option selected disabled>---</option>
                            <option value='equal_to'>{{ $t('isEqualTo') }}</option>
                            <option value='greater_than'>{{ $t('isGreaterThan') }}</option>
                            <option value='between'>{{ $t('isBetween') }}</option>
                            <option value='less_than'>{{ $t('isLessThan') }}</option>
                        </select>
                    </div>
                    <div class="form-group">
                        <div class="flex justify-between" v-if="priceObject.range === 'between'">
                            <input type="number" min="0" v-model="priceObject.start" class="form-control">
                            <input type="number" min="0" v-model="priceObject.end" class="form-control">
                        </div>
                        <div v-else>
                            <input type="number" min="0" v-model="priceObject.amount" class="form-control">
                        </div>
                    </div>
                </div>
                <div>
                    <div class="bg-white border-b">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="supplier"/>
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('supplier') }}</span>
                        </label>
                    </div>
                    <div v-if="filter === 'supplier'" class="p-2">
                        <div class="form-group">
                            <input type="text" v-model="supplier.name" class="form-control">
                        </div>
                    </div>
                </div>

                <div>
                    <div class="bg-white border-b">
                        <label class="inline-flex align-items-center item-center mt-1 ml-3">
                            <input type="radio" class="form-radio cursor-pointer" v-model="filter" value="quantity"/>
                            <span class="ml-2 cursor-pointer text-xs">{{ $t('quantity') }}</span>
                        </label>
                    </div>
                    <div v-if="filter === 'quantity'" class="p-2">
                        <div class="form-group">
                            <input type="number" min="0" v-model="quantity.value" class="form-control">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name : 'FilterComponent',
        props : {
            data : {
                type : String,
                required : false
            }
        },
        data () {
            return {
                filter : '',
                value1 : '',
                priceObject : {
                    range : '',
                    amount : '',
                    start : '',
                    end : ''
                },
                quantity : {
                    value : '',
                },
                supplier : {
                    name : ''
                }
            }
        },
        watch : {
            filter : function (newVal, _) {
                this.filter = ''
                this.filter = newVal
            }
        },
        methods : {
            filterDataNow () {
                if (this.filter === 'price') {
                    if (this.priceObject.range && (this.priceObject.amount || this.priceObject.start || this.priceObject.end)) {
                        if (this.priceObject.range === 'between') {
                            if (this.priceObject.start >= this.priceObject.end) {
                                const tmp = this.priceObject.start
                                this.priceObject.start = this.priceObject.end
                                this.priceObject.end = tmp
                                this.$emit('filterWebshopPrice', this.priceObject)
                            } else {
                                this.$emit('filterWebshopPrice', this.priceObject)
                            }
                        } else {
                            this.$emit('filterWebshopPrice', this.priceObject)
                        }
                        // if (this.data === 'webshop') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_AMOUNT_WEBSHOP_ORDERS', this.priceObject)
                        // }

                        // if (this.data === 'supplier') {
                        //     this.$store.commit('jitcontrol/ADVANCE_FILTER_AMOUNT_SUPPLIER_ORDERS', this.priceObject)
                        // }
                    }
                }

                if (this.filter === 'supplier') {
                    if (this.supplier && this.supplier.name) {
                        this.$emit('filterSupplier', this.supplier)
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_TYPE', this.supplier)
                    }
                }

                if (this.filter === 'quantity') {
                    if (this.quantity && this.quantity.value) {
                        this.$emit('filterStockQuantity', this.quantity)
                        // this.$store.commit('jitcontrol/ADVANCE_FILTER_SUPPLIER_TYPE', this.supplier)
                    }
                }

                this.clearDataField(null)
                this.$emit('hideAdvanceFilter')
            },

            clearDataField (data) {
                this.filter = ''
                Object.keys(this.priceObject).forEach(element => { this.priceObject[element] = '' })
                Object.keys(this.quantity).forEach(element => { this.quantity[element] = '' })
                Object.keys(this.supplier).forEach(element => { this.supplier[element] = '' })
                if (data) {
                    this.$emit('resetProduct')
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";
.el-date-editor--daterange.el-input__inner{
    width: unset !important;
}
.form-group{
    margin-bottom: 0.5rem !important;
}
.form-control, .form-select{
    border: 1px solid $form-border !important;
    height: unset !important;
    padding: .175rem .75rem !important;
}
.form-control:focus{
    box-shadow: unset !important;
    border: 1px solid $theme-primary-color !important;
}
</style>